<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/importacao-cliente"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
            >
                <template #content>
                    <div>
                        <Message severity="info">
                            <div>
                                <p>A tela de importações deve ser utilizada para IMPORTAR os dados diretamente do sistema SOC. Você pode selecionar de qual empresa e qual origem você queira atualizar.</p>
                            </div>
                        </Message>
                        <div v-if="!loading" class="grid">
                            <div class="field col-6">
                                <label for="cliente">Empresa</label>
                                <MultiSelect
                                    id="cliente"
                                    :disabled="!!form.id"
                                    v-model="clientesSelecionados"
                                    :options="clientes"
                                    :filter="true"
                                    optionLabel="name"
                                    dataKey="id"
                                    display="chip"
                                    :showToggleAll="false"
                                    placeholder="Escolha"
                                    :class="{ 'p-invalid': submitted && !clientesSelecionados.length }"
                                />
                                <small class="p-error" v-if="submitted && !clientesSelecionados.length">Campo obrigatório.</small>
                            </div>
                            <div class="field col-6" v-if="!form.id">
                                <label for="origens">Origens</label>
                                <MultiSelect
                                    id="origens"
                                    :disabled="true"
                                    v-model="origensSelecionadas"
                                    :options="origens"
                                    :filter="true"
                                    display="chip"
                                    optionLabel="name"
                                    placeholder="Escolha"
                                    :class="{ 'p-invalid': submitted && !origensSelecionadas.length }"
                                />
                                <small class="p-error" v-if="submitted && !origensSelecionadas.length">Campo obrigatório.</small>
                            </div>
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getClientBase } from '../../services/http';

export default {
    data() {
        return {
            loading: false,
            service: null,
            form: {},
            submitted: false,
            items: {
                criadas: [],
                iniciadas: [],
                sucessos: [],
                erros: []
            },
            clientes: [],
            companies: [],
            origensSelecionadas: [],
            clientesSelecionados: [],
            origens: [
                {
                    id: 'SETOR',
                    name: 'SETOR'
                },
                {
                    id: 'CARGO',
                    name: 'CARGO'
                },
                {
                    id: 'HIERARQUIA',
                    name: 'HIERARQUIA'
                },
                {
                    id: 'FUNCIONARIO',
                    name: 'FUNCIONARIO'
                }
            ]
        };
    },
    created() {
        this.service = new BaseService('/importacao-cliente');
    },
    mounted() {
        this.origensSelecionadas = this.origens;
        if (this.$route.params.id) {
            this.findItensGroupByStatus(this.$route.params.id);
        }
        this.loadCustomers();
        this.loadCompanies();
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Visualizar importação' : 'Adicionar importação';
        }
    },
    methods: {
        async onLoadDataEdit(data) {
            this.form = data;
        },
        async onBeforeSave() {
            this.submitted = true;
            this.form.origens = this.origensSelecionadas.map((p) => p.id);
            this.form.clientes = this.clientesSelecionados.map((p) => p.id);
        },
        async onAfterSave() {
            this.submitted = false;
        },
        async findItensGroupByStatus(id) {
            const response = await getClientBase().get('/importacao-cliente/find-itens-group-by-status/' + id);
            this.items.criadas = response.data.criadas;
            this.items.iniciadas = response.data.iniciadas;
            this.items.sucessos = response.data.sucessos;
            this.items.erros = response.data.erros;
        },
        async loadCustomers() {
            const { data } = await getClientBase().get('/customers/combo');
            this.clientes = data;
        },
        async loadCompanies() {
            const { data } = await getClientBase().get('/companies/combo');
            this.companies = data;
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }

            return cbSuccess();
        },
        hasError() {
            if (!this.clientesSelecionados.length) return true;
            if (!this.origensSelecionadas.length) return true;
        },
    }
};
</script>
